import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Icd10Code } from '../model/icd10Code';
import { NappiCode } from '../model/nappiCode';
import { StateService } from './state.service';
import { ConsumableCode } from '../model/consumableCode';

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  constructor(
    private http: HttpClient, private stateService: StateService) { }

  private icd10CodeUrl = 'static/icd10codes';
  private nappiCodeUrl = 'static/nappicodes';
  private consumableCodeUrl = 'static/consumablecodes';

  searchIcd10Codes(term: string): Observable<Icd10Code[]> {
    return this.http.get<Icd10Code[]>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.icd10CodeUrl}/?searchKey=${term}`);
  }

  searchNappiCodes(term: string): Observable<NappiCode[]> {
    return this.http.get<NappiCode[]>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.nappiCodeUrl}/?searchKey=${term}`);
  }

  searchConsumableCodes(term: string): Observable<ConsumableCode[]> {
    return this.http.get<ConsumableCode[]>(
      `${this.stateService.getValue('env')['baseUrl']}/${this.consumableCodeUrl}/?searchKey=${term}`);
  }
}

