import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Patient } from '../../model/patient';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { StateService } from '../../service/state.service';
import { UserService } from '../../service/user.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { Icd10Code } from '../../model/icd10Code';
import { StaticDataService } from '../../service/static-data.service';
import { NappiCode } from '../../model/nappiCode';
import { ConsultationService } from '../../service/consultation.service';
import { HistoryItem } from '../../model/history-item';
import { Prescription } from '../../model/prescription';
import { RenderedService } from '../../model/rendered-service';
import { UploadFileService } from '../../service/upload-file.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material';
import { Practitioner } from '../../model/practitioner';
import { ConsumableCode } from '../../model/consumableCode';

@Component({
  selector: 'app-diagnose',
  templateUrl: './diagnose.component.html',
  styleUrls: ['./diagnose.component.css']
})
export class DiagnoseComponent implements AfterViewInit {

  @ViewChild(SignaturePad, { static: false }) signaturePad: SignaturePad;

  public signaturePadOptions: any = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 800,
    'canvasHeight': 300
  };

  imagePath: string;
  writtingMode: boolean;

  icd10SearchCtrl = new FormControl();
  filteredIcd10Codes: Observable<Icd10Code[]>;
  chosenICD10Codes: Set<Icd10Code> = new Set();
  icd10Codes: Observable<Icd10Code[]>;
  icd10Code: Icd10Code;

  nappiCodeSearchCtrl = new FormControl();
  consumableCodeSearchCtrl = new FormControl();
  filteredNappiCodes: Observable<NappiCode[]>;
  filteredConsumableCodes: Observable<ConsumableCode[]>;
  nappiCodes: Observable<NappiCode[]>;
  consumableCodes: Observable<ConsumableCode[]>;
  nappiCode: NappiCode;
  consumableCode: ConsumableCode;
  chosenNappis: Set<NappiCode> = new Set();
  chosenConsumables: Set<ConsumableCode> = new Set();
  patient: Patient = new Patient();
  nappi: NappiCode;
  consumable: ConsumableCode;
  icd10: Icd10Code;

  practitioners: Practitioner[];
  selectedPractitioner: Practitioner;

  constructor(private stateService: StateService, private staticDataService: StaticDataService,
    private consultationService: ConsultationService, private uploadService: UploadFileService,
    private userService: UserService,
    private snackBar: MatSnackBar, private router: Router) {

    this.userService.getPractitioners().subscribe(result => {
      this.practitioners = result;
      this.selectedPractitioner = this.practitioners[0];
    });

    this.patient = this.stateService.getValue('patient');

    this.icd10Codes = this.staticDataService.searchIcd10Codes('');

    this.filteredIcd10Codes = this.icd10SearchCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(val => {
        let filterValue = '';
        if (val) {
          if (val.code) {

            filterValue = val.code;
          } else {
            filterValue = val;
          }
        }
        return this.filterIcd10Codes(filterValue);
      })
    );

    this.nappiCodes = this.staticDataService.searchNappiCodes('');
    this.consumableCodes = this.staticDataService.searchNappiCodes('');

    this.filteredNappiCodes = this.nappiCodeSearchCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(val => {
        let filterValue = '';
        if (val) {
          if (val.code) {

            filterValue = val.code;
          } else {
            filterValue = val;
          }
        }

        return this.filterNappiCodes(filterValue);
      })
    );


    this.filteredConsumableCodes = this.consumableCodeSearchCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(val => {
        let filterValue = '';
        if (val) {
          if (val.code) {

            filterValue = val.code;
          } else {
            filterValue = val;
          }
        }

        return this.filterConsumableCodes(filterValue);
      })
    );
  }

  routeTo(patient: Patient, location: string) {

    this.stateService.setValue('patient', patient);
    this.router.navigate([location]);
  }

  filterNappiCodes(val: string) {

    this.nappiCodes = this.staticDataService.searchNappiCodes(val);

    return this.nappiCodes.pipe(
      map(response => response.filter(option => {
        return option.code.toLowerCase().includes(val.toLowerCase())
          || option.description.toLowerCase().includes(val.toLowerCase())
          || option.impCode.toLowerCase().includes(val.toLowerCase())
          || option.secondCode.toLowerCase().includes(val.toLowerCase())
          || option.s01Code.toLowerCase().includes(val.toLowerCase())
          || option.secondDescription.toLowerCase().includes(val.toLowerCase())
          || option.dateDescription.toLowerCase().includes(val.toLowerCase())
          || option.rpeCode.toLowerCase().includes(val.toLowerCase());
      }))
    );
  }

  filterConsumableCodes(val: string) {

    this.consumableCodes = this.staticDataService.searchConsumableCodes(val);

    return this.consumableCodes.pipe(
      map(response => response.filter(option => {
        return option.code.toLowerCase().includes(val.toLowerCase())
          || option.description.toLowerCase().includes(val.toLowerCase())
          || option.impCode.toLowerCase().includes(val.toLowerCase())
          || option.secondCode.toLowerCase().includes(val.toLowerCase())
          || option.s01Code.toLowerCase().includes(val.toLowerCase())
          || option.secondDescription.toLowerCase().includes(val.toLowerCase())
          || option.dateDescription.toLowerCase().includes(val.toLowerCase())
          || option.rpeCode.toLowerCase().includes(val.toLowerCase());
      }))
    );
  }

  filterIcd10Codes(val: string) {

    this.icd10Codes = this.staticDataService.searchIcd10Codes(val);

    return this.icd10Codes.pipe(
      map(response => response.filter(option => {
        return option.code.toLowerCase().includes(val.toLowerCase())
          || option.description1.toLowerCase().includes(val.toLowerCase())
          || option.description2.toLowerCase().includes(val.toLowerCase());
      }))
    );
  }

  displayNappi(nappi: NappiCode) {
    if (nappi) {
      return nappi.code;
    } else {
      return '';
    }
  }

  displayConsumable(consumable: ConsumableCode) {
    if (consumable) {
      return consumable.code;
    } else {
      return '';
    }
  }

  chooseNappi(nappi: NappiCode) {
    this.nappi = nappi;
  }

  chooseConsumable(consumable: ConsumableCode) {
    this.consumable = consumable;
  }

  choosePatient(patient: Patient): void {

    this.patient = patient;
  }

  displayIcd10(icd10: Icd10Code) {
    if (icd10) {
      return icd10.code;
    } else {
      return '';
    }
  }

  chooseIcd10(icd10: Icd10Code) {
    this.icd10 = icd10;
  }

  makeReferral(patient: Patient, practitioner: Practitioner) {
    this.consultationService.completeReferral(patient, practitioner)
    .subscribe(result => this.snackBar.open('Referrel Complete', null, {
      duration: 2000,
    }));
  }

  complete() {

    let renderedService: RenderedService = new RenderedService();
    renderedService.items = Array.from(this.chosenICD10Codes).map(icd10 => {
      return { icd10: { "id": icd10.id }, fee: 50000, quantity: 2 }
    }
    );
    renderedService.levy = 10000;
    renderedService.totalDue = 10000;

    let item: HistoryItem = new HistoryItem();
    item.patient = this.patient;
    item.renderedService = renderedService;

    if (this.imagePath) {
      const blob: any = this.dataURItoBlob(this.imagePath);
      blob.name = "handWrittenNotes.png";
      blob.lastModifiedDate = new Date();
      const file: File = <File>blob;
      item.handWrittenNotesFile = file;
    }

    if (this.chosenNappis.size > 0) {
      let prescription: Prescription = new Prescription();
      prescription.items = Array.from(this.chosenNappis).map(nappi => {
        return { nappiCode: { "id": nappi.id }, price: 20000, quantity: 1, frequency: "daily", dosage: "5ml" }
      }
      );
      prescription.levy = 10000;
      prescription.totalDue = 10000;
      item.prescription = prescription;
    }

    this.consultationService.completeConsultation(item)
      .subscribe(result => this.snackBar.open('Consultation has been captured', null, {
        duration: 2000,
      }));
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    let ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    let blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 1); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() { }

  drawStart() { }

  clear() {
    this.signaturePad.clear();
  }

  doneWritting() {
    this.imagePath = this.signaturePad.toDataURL();
    this.writtingMode = false;
  }

  ///
  selectedFiles: FileList;
  currentFileUpload: File;
  progress: { percentage: number } = { percentage: 0 };
  selectedFile = null;
  changeImage = false;
  uploadOptions = [
    { key: 'XRAY', label: 'X-Ray Results' },
    { key: 'BLOOD', label: 'Blood Results' },
    { key: 'SPUTUM', label: 'Sputum Results' },
    { key: 'URINE', label: 'Urine Results' },
  ];
  chosenUploadOption = null;

  change($event) {
    this.changeImage = true;
  }
  changedImage(event) {
    this.selectedFile = event.target.files[0];
  }
  upload() {
    this.progress.percentage = 0;
    this.currentFileUpload = this.selectedFiles.item(0);
    this.uploadService.pushFileToStorage(this.currentFileUpload, this.chosenUploadOption.key,
      this.patient.id).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress.percentage = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.snackBar.open('File Successfully Uploaded', null, {
            duration: 2000,
          });
        }
        this.selectedFiles = undefined;
      }
      );
  }
  selectFile(event) {
    this.selectedFiles = event.target.files;
  }
}
